import React from 'react'
import Layout from '../../components/layout'
import { Link } from 'gatsby'
import Song from '../../components/song'

const Round = () => (
  <Layout>
    <Song/>
  </Layout>
)

export default Round
