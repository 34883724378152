import React, { Component } from "react"

class Song extends Component {
  render() {
    return (
      <div className="song-container">
          <section className="hero is-primary is-primary">
            <div className="hero-body has-bg-img secondary">
              <div className="container has-text-centered">
                <span className="subtitle">hey big guy, watch me fly</span>
              </div>
            </div>
          </section>
          <section className="section song">
            <div className="container song is-fluid">
              <div className="columns">
                <div className="column is-half art round">
                  <blockquote>
                    <p>Do you hear&hellip;</p>
                    <p>lost and lonely child&hellip;</p>
                    <p>another spirit whispers in the sky?</p>
                  </blockquote>
                </div>
                <div className="column is-one-quarter lyrics">
                  <p>
                    I’m round <br/>
                    Solid round<br/>
                    I’m round <br/>
                    Solid round
                  </p>

                  <p>
                    I am all there is or needs to be.<br/>
                    My entire being surrounds me.<br/>
                    A world of sand and dust, built on solid rock<br/>
                    I am a planet- look at me
                  </p>

                  <p>
                    Do you hear, lost and lonely child? <br/>
                    Another spirit whispers in the sky <br/>
                    You’re not alone, you’re not the only thing <br/>
                    I am with you flying high
                  </p>

                  <p>
                    Trying to get my head around this now <br/>
                    there’s something else- duality? <br/>
                    Why would I need this, some kind of parasite <br/>
                    Messin’ with my geometry?
                  </p>

                  <p>
                    I complete you- add a touch of class <br/>
                    Even if my charms you cannot see <br/>
                    But something tells me you want someone <br/>
                    Why’re you always looking at me?
                  </p>

                  <p>
                    I’m round<br/>
                    So round<br/>
                    Solid round<br/>
                    We fly so high
                  </p>

                  <p>
                    I’m round<br/>
                    So round<br/>
                    Solid round<br/>
                    High above the ground
                  </p>

                  <p>
                    Now let me think!
                  </p>
                </div>

                <div className="column is-one-quarter lyrics">
                  <p>
                    This is something good I know that it’s to be <br/>
                    I can envision harmony <br/>
                    You bring me air and rain and manna from the stars <br/>
                    I’ll provide the gravity
                  </p>

                  <p>
                    I knew you’d come around, my silly ball of stone <br/>
                    Though it took a million years to see <br/>
                    I’ll bring you special gifts that you will adore and <br/>
                    shelter you from cosmic debris
                  </p>

                  <p>
                    I can be a little dense... for that you must forgive <br/>
                    and you’re a flighty tart- agree? <br/>
                    But now we’re bound forever mated in the void <br/>
                    One for all eternity
                  </p>

                  <p>
                    I’ll love you dearly, hold you tenderly <br/>
                    The universe will know our bliss <br/>
                    Children will soon come forth across our sphere <br/>
                    Wonders follow on our kiss
                  </p>

                  <p>
                    We’re round<br/>
                    So round<br/>
                    Solid round<br/>
                    We fly so high
                  </p>

                  <p>
                    We’re round<br/>
                    So round<br/>
                    Solid round<br/>
                    Lovers world and sky
                  </p>

                  <p>
                    We’re round<br/>
                    So round<br/>
                    Solid round<br/>
                    We fly so high
                  </p>

                  <p>
                    We’re round<br/>
                    So round<br/>
                    Solid round<br/>
                    Lovers world and sky
                  </p>
                </div>
              </div>
            </div>
        </section>
      </div>
    )
  }
}

export default Song